import { shuffle } from "lodash";
import parse from "html-react-parser";

export const appInitialState = {
	cardsData: [], //raw data read from json
	cardsGridArray: [], //actual card instances - array of: {gridX, gridY, zValue, cardDataIdx, shiftRatioX, shiftRatioY}
	ga4tracker: null,
};

export const appStateReducer = (state, action) => {
	console.log("appState Reducer:", action);
	switch (action.type) {
		case "setCardsData":
			console.log("Setting cards data to:", action.value);
			// console.log("Will return: ", { ...state, cardsData: action.value });
			const processsedCardsData = action.value.map((oneCard) => {
				if (oneCard.type === "quote") {
					oneCard.parsedContent = parse(oneCard.content);
					console.log("Parsed content is:", oneCard.parsedContent);
				}
				return oneCard;
			});
			//
			// let oneCard = action.value;
			// if (oneCard.type === "quote") {
			// 	oneCard.parsedContent = parse(oneCard.content);
			// 	console.log("Parsed content is:", oneCard.parsedContent);
			// }
			return { ...state, cardsData: processsedCardsData };
		case "initCardsOrder":
			console.log("initial cards data:", state.cardsData);
			const shuffledCards = shuffle(state.cardsData);
			const sortedData = shuffledCards.sort((card1, card2) => {
				if (card1.is_pinned || card2.is_pinned) {
					if (card1.is_pinned && !card2.is_pinned) {
						return -1;
					} else if (!card1.is_pinned && card2.is_pinned) {
						return 1;
					} else {
						return 0;
					}
				} else {
					//cards are shuffled already, so we first push up the pinned ones, and leave the already
					// shuffled ones as is (meaning - shuffled)
					return 0; //card1.id < card2.id ? -1 : 1;
				}
			});
			console.log("Returning sorted data:", sortedData);
			return { ...state, cardsData: sortedData };
		case "addItemsToCardsGridArray":
			return { ...state, cardsGridArray: state.cardsGridArray.concat(action.value) };
		case "setCardsGridArray":
			return { ...state, cardsGridArray: action.value };
		case "setGA4Tracker":
			return { ...state, ga4tracker: action.value };
		default:
			// throw new Error("unknown appstate dispatch:", action.type, action.value);
			return state;
	}
};
